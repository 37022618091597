import React, { useState } from "react"
import { encode } from "src/utils/encode"
import ArrowLeft from "../../images/arrow-left.svg"
import ArrowRight from "../../images/arrow-right.svg"
import CheckIcon from "../../images/check-icon.svg"
import FacebookIcon from "../../images/facebook.svg"
import LinkedInIcon from "../../images/instagram.svg"
import InstagramIcon from "../../images/linkedin.svg"
import LogoTMBP from "../../images/tmbp-icon-v2.svg"
import "./ComingSoon.scss"

export const ComingSoon = () => {
  const [state, setState] = useState({})
  const [showThanks, setShowThanks] = useState(false)

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: { preventDefault: () => void; target: any }) => {
    e.preventDefault()

    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setState({})
        form.reset()
        setShowThanks(true)
      })
      .catch(error => alert(error))
  }

  return (
    <div className="w-full min-h-screen h-auto flex justify-center text-center py-8 sm:py-16 overflow-hidden coming-soon-wrapper">
      <div className="flex flex-col items-center">
        <LogoTMBP />
        <h1 className="font-semibold font-gilroy text-primary-500 mt-12 mb-6">New Website Coming Soon</h1>
        <p className="text-white mb-4 sm:mb-12">Until then, reach out for more information</p>

        <section className="text-black w-full p-4 sm:p-0 sm:container sm:max-w-150">
          {showThanks ? (
            <div className="flex flex-col items-center gap-y-10 bg-mono p-6">
              <CheckIcon className="text-primary-500" />
              <h2>Thank you!</h2>

              <div>
                <p>
                  Your message has been sent. <span className="hidden sm:inline">We will contact you shortly.</span>
                </p>
                <p className="inline sm:hidden">We will contact you shortly.</p>
                <p> Meanwhile, you can find more information on our social pages.</p>
              </div>

              <button
                className="bg-black group hover:bg-primary-500 text-white uppercase py-4 px-8 flex gap-x-2 items-center justify-between w-fit transition-all duration-300"
                type="button"
                onClick={() => setShowThanks(false)}
              >
                <ArrowLeft className="text-white group-hover:-translate-x-2 transition-all duration-300" />
                <span className="inline-block">BACK TO CONTACT FORM</span>
              </button>
            </div>
          ) : (
            <form
              className="bg-mono p-6 sm:p-12"
              name="contact-form"
              data-netlify="true"
              netlify-honeypot="yourId"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact-form" />

              <label className="hidden">
                Your ID: <input name="yourId" onChange={handleChange} />
              </label>

              <div className="relative z-0 w-full mb-8">
                <input
                  required
                  type="text"
                  name="name"
                  placeholder=" "
                  className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b border-black appearance-none focus:outline-none focus:ring-0 rounded-none"
                  onChange={handleChange}
                />

                <label
                  htmlFor="name"
                  className="absolute text-base duration-300 top-3 left-0 origin-0 text-grey-5 -z-10"
                >
                  Name
                </label>
              </div>

              <div className="sm:grid sm:grid-cols-2 gap-x-6">
                <div className="col-span-1">
                  <div className="relative z-0 w-full mb-8">
                    <input
                      required
                      type="email"
                      name="email"
                      placeholder=" "
                      className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b border-black appearance-none focus:outline-none focus:ring-0 rounded-none"
                      onChange={handleChange}
                    />

                    <label
                      htmlFor="email"
                      className="absolute text-base duration-300 top-3 left-0 origin-0 text-grey-5 -z-10"
                    >
                      Email
                    </label>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="relative z-0 w-full mb-8">
                    <input
                      required
                      type="text"
                      name="phone"
                      placeholder=" "
                      className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b border-black appearance-none focus:outline-none focus:ring-0 rounded-none"
                      onChange={handleChange}
                    />

                    <label
                      htmlFor="phone"
                      className="absolute text-base duration-300 top-3 left-0 origin-0 text-grey-5 -z-10"
                    >
                      Phone
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex-col col-span-2 sm:col-span-1 sm:flex">
                <div className="relative z-0 w-full mb-8">
                  <textarea
                    required
                    rows={4}
                    name="messages"
                    placeholder=" "
                    className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b border-black appearance-none focus:outline-none focus:ring-0 rounded-none"
                    onChange={handleChange}
                  />

                  <label
                    htmlFor="messages"
                    className="absolute text-base duration-300 top-3 left-0 origin-0 text-grey-5 -z-10"
                  >
                    How can we help?
                  </label>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="w-fit text-sm text-primary-500">All fields are required</div>
                <button
                  className="bg-black group hover:bg-primary-500 text-white uppercase p-4 sm:py-4 sm:px-8 flex gap-x-2 items-center justify-between w-fit transition-all duration-300"
                  type="submit"
                >
                  <span className="inline-block">SEND</span>
                  <ArrowRight className="text-white group-hover:translate-x-2 transition-all duration-300" />
                </button>
              </div>
            </form>
          )}
        </section>

        <p className="text-white mt-4 sm:mt-12 mb-6">Check out what we do on our socials</p>

        <div className="flex items-end justify-center gap-x-6">
          <a
            target="_blank"
            href="https://www.facebook.com/themovebetterproject"
            aria-label="Facebook"
            className="group"
          >
            <FacebookIcon className="text-primary-500 group-hover:text-grey-3  transition-all duration-300" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/the-move-better-project/"
            aria-label="LinkedIn"
            className="group"
          >
            <LinkedInIcon className="text-primary-500 group-hover:text-grey-3 transition-all duration-300" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/themovebetterproject/"
            aria-label="Instagram"
            className="group"
          >
            <InstagramIcon className="text-primary-500 group-hover:text-grey-3 transition-all duration-300" />
          </a>
        </div>
      </div>
    </div>
  )
}
